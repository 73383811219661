<template>
  <v-dialog
    :value="visible"
    width="90%"
    @change="$emit('close')"
    @close="$emit('close')"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-system-bar>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
    </v-system-bar>
    <v-card>
      <v-card-text id="content-to-print">
        <v-card-title>{{ subject.title }}</v-card-title>
        <v-card-subtitle>
          {{ $d(from_, "short") }} - {{ $d(to_, "short") }}
        </v-card-subtitle>
        <v-row>
          <v-col cols="12" style="min-height: 512px">
            <div ref="chart" class="chart"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="-1"
              dense
              hide-default-footer
            >
              <template v-slot:item.event="{ item }">
                <span
                  >{{ item.event }}
                  {{ item.topic ? " [" + item.topic + "]" : "" }}</span
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" fab @click="exportToPDF">
          <v-icon>print</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  name: "AnalyticsDialog",
  props: {
    visible: { type: Boolean, required: true },
    to: { required: false },
    from: { required: false },
    items: { type: Array, required: true },
    headers: { type: Array, required: true },
    points: { required: true },
    subject: { required: true, type: Object },
  },
  data() {
    return {
      chartObject: null,
      chartData: null,
      chartItems: [],
    };
  },
  computed: {
    from_() {
      if (!this.from) return undefined;
      return new Date(this.from);
    },
    to_() {
      if (!this.to) return undefined;
      return new Date(this.to);
    },
  },
  watch: {
    visible(val) {
      if (!val) return;

      this.chartLoader()
        .then((api) => {
          google = api;
          this.chartObject = new google.visualization.LineChart(
            this.$refs.chart
          );

          const chart = this.chartObject;
          const target = this.$refs.chart;

          google.visualization.events.addListener(chart, "ready", function () {
            target.innerHTML = '<img src="' + chart.getImageURI() + '">';
          });
        })
        .then(() => this.drawChart());
    },
  },
  methods: {
    exportToPDF() {
      let element = document.getElementById("content-to-print");

      const pdf = new jsPDF({
        orientation: "landscape",
        format: "a4",
      });
      html2canvas(element).then((canvas) => {
        let wid;
        let hgt;
        let image = canvas.toDataURL(
          "image/png",
          (wid = canvas.width),
          (hgt = canvas.height)
        );
        const hratio = hgt / wid;
        const width = pdf.internal.pageSize.width;
        const height = width * hratio;
        pdf.addImage(
          image,
          "JPEG",
          width * 0.025,
          20,
          width * 0.95,
          height * 0.95
        );
        pdf.save(`export.pdf`);
        this.$emit("close");
      });
    },
    chartLoader() {
      if (window.google && window.google.charts) {
        return Promise.resolve(window.google);
      }

      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = () => resolve(window.google.charts);
        script.src = "https://www.gstatic.com/charts/loader.js";
        document.body.appendChild(script);
      }).then((loader) => {
        return new Promise((res) => {
          loader.load("current", { packages: ["corechart"] });
          loader.setOnLoadCallback(() => res(window.google));
        });
      });
    },
    drawChart() {
      const items = this.items;
      const chartData = (this.chartData = new google.visualization.DataTable());

      this.chartData.addColumn("date", "Date");

      for (let item of items) {
        this.chartData.addColumn(
          "number",
          item.event + (item.topic ? " [" + item.topic + "]" : "")
        );
      }

      for (let point of Object.keys(this.points)) {
        let row = [];
        row.push(new Date(point));
        for (let item of items) {
          row.push(item.point[point]);
        }
        chartData.addRow(row);
      }

      const options = {
        width: undefined,
        height: undefined,
        vAxis: {
          format: "decimal",
        },
        animation: {
          duration: 500,
          easing: "out",
          startup: true,
        },
      };

      if (!this.chartObject) return;
      this.chartObject.draw(chartData, options);
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  min-height: 512px;
}
</style>
