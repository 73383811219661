<template>
  <v-card>
    <v-card-title>
      <span>{{ $t("subscriptions.title") }}</span>
      <v-spacer />
      <v-chip v-if="subscription.premium">
        {{ $t("subscriptions.premium") }}
      </v-chip>
      <v-chip class="ml-1">
        {{ $t("subscriptions.status." + subscription.status) }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          {{ $d(new Date(subscription.startDate), "short") }}
        </v-col>
        <v-col cols="4" v-if="subscription.endDate">
          {{ $d(new Date(subscription.endDate), "short") }}
        </v-col>
        <v-col cols="4">
          <v-icon class="mr-1">autorenew</v-icon>
          <span>{{ subscription.billingInterval }}</span>
          <span>
            {{ $t("billingPeriods." + subscription.billingPeriod) }}
          </span>
        </v-col>
      </v-row>
      <v-row v-if="subscription.premiumTopics || subscription.standardTopics">
        <v-col cols="12" md="6">
          <v-list dense>
            <v-subheader> {{ $t("topics.standardTitle") }}: </v-subheader>
            <v-list-item
              v-for="topic in subscription.standardTopics"
              :key="topic.uid"
            >
              {{ topic.name }}
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" md="6">
          <v-list dense>
            <v-subheader> {{ $t("topics.premiumTitle") }}: </v-subheader>
            <v-list-item
              v-for="topic in subscription.premiumTopics"
              :key="topic.uid"
            >
              {{ topic.name }}
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-list-item class="grow">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon class="mr-1">calendar_today</v-icon>
            <span v-if="subscription.nextPaymentDate">
              {{ $d(new Date(subscription.nextPaymentDate), "short") }}
            </span>
          </v-list-item-title>
        </v-list-item-content>

        <v-row align="center" justify="end">
          <v-icon class="mr-1">euro</v-icon>
          <span class="subheading mr-1">{{ subscription.total }} </span>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    subscription: { type: Object, required: true }
  }
};
</script>

<style></style>
