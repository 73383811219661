<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12" style="min-height: 512px">
          <div ref="chart" class="chart"></div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-actions>
      <v-btn-toggle v-model="level" class="ml-5" gropu mandatory tile>
        <v-btn
          :loading="loading"
          :value="'DAY'"
          class="mr-2"
          elevation="3"
          outlined
          rounded
        >
          {{ $t("subscriptions.forecast.day") }}
        </v-btn>
        <v-btn
          :loading="loading"
          :value="'WEEK'"
          class="mr-2"
          elevation="3"
          outlined
          rounded
        >
          {{ $t("subscriptions.forecast.week") }}
        </v-btn>
        <v-btn
          :loading="loading"
          :value="'MONTH'"
          class="mr-2"
          elevation="3"
          outlined
          rounded
        >
          {{ $t("subscriptions.forecast.month") }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer />
      <v-menu
        v-model="fromMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="from"
            :label="$t('subscriptions.forecast.from')"
            :loading="loading"
            prepend-icon="calendar"
            readonly
            solo
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="from" @input="fromMenu = false"></v-date-picker>
      </v-menu>
      <v-menu
        v-model="toMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="to"
            :label="$t('subscriptions.forecast.to')"
            :loading="loading"
            prepend-icon="calendar"
            readonly
            solo
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
      </v-menu>
      <v-btn icon @click="printDialog = true">
        <v-icon>print</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="resultItems"
        dense
        item-key="event"
        show-expand
      >
        <template v-slot:header.chart>
          <v-btn icon small @click="clearChart()">
            <v-icon>visibility_off</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.chart="{ item }">
          <v-btn
            v-if="chartItems.includes(item)"
            fab
            icon
            small
            @click="removeChart(item)"
          >
            <v-icon>visibility_off</v-icon>
          </v-btn>
          <v-btn v-else fab icon small @click="addChart(item)">
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              :headers="subHeaders"
              :items="Object.values(item.aggregate)"
              :items-per-page="-1"
              class="mt-3 mb-3"
              dense
              hide-default-footer
              item-key="topic"
              sort-by="topic"
            >
              <template v-slot:item.chart="{ item }">
                <v-btn
                  v-if="chartItems.includes(item)"
                  fab
                  icon
                  small
                  @click="removeChart(item)"
                >
                  <v-icon>visibility_off</v-icon>
                </v-btn>
                <v-btn v-else fab icon small @click="addChart(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>

      <analytics-dialog
        :from="result.from"
        :headers="headers"
        :items="chartItems"
        :points="result.point"
        :subject="subject"
        :to="result.to"
        :visible="printDialog"
        @close="printDialog = false"
      ></analytics-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import AnalyticsDialog from "@/components/analytics/AnalyticsDialog";

export default {
  name: "AnalyticsCard",
  components: { AnalyticsDialog },
  props: {
    subject: { type: Object, required: true },
    url: { type: String, required: true },
  },
  data: () => {
    return {
      result: {},
      headers: [],
      subHeaders: [],
      loading: false,
      error: null,
      level: "MONTH",
      from: null,
      to: null,
      fromMenu: false,
      toMenu: false,
      chartObject: null,
      chartData: null,
      chartItems: [],
      printDialog: false,
    };
  },
  mounted() {
    this.chartLoader()
      .then((api) => {
        google = api;
        this.chartObject = new google.visualization.LineChart(this.$refs.chart);
      })
      .then(() => this.fetch());
  },
  beforeDestroy() {
    if (this.chartObject && typeof this.chartObject.clearChart === "function") {
      this.chartObject.clearChart();
    }
  },
  watch: {
    level() {
      this.fetch();
    },
    from() {
      this.fetch();
    },
    to() {
      this.fetch();
    },
  },
  computed: {
    resultItems() {
      if (!this.result.items) return [];
      return Object.entries(this.result.items).map(([key, value]) => value);
    },
  },
  methods: {
    fetch() {
      if (this.loading) return;

      this.loading = true;
      this.error = null;

      let searchParams = new URLSearchParams();
      searchParams.append("from", this.from || "");
      searchParams.append("to", this.to || "");
      searchParams.append("level", this.level);

      this.$http
        .get(this.url, {
          params: searchParams,
        })
        .then((r) => (this.result = this.convertResponse(r.data)))
        .then(() => (this.headers = this.createHeaders(this.result)))
        .then(() => (this.subHeaders = this.createHeaders(this.result, true)))
        .then(() => {
          this.chartItems.splice(0, this.chartItems.length);
          for (let item of Object.values(this.result.items)) {
            this.chartItems.push(item);
            this.drawChart();
          }
        })
        .catch((err) => (this.error = err))
        .finally(() => (this.loading = false));
    },

    convertResponse(response) {
      const result = {
        level: response.level,
        from: response.from,
        to: response.to,
        point: {},
        items: {},
      };

      for (let item of response.items) {
        if (!result.items[item.event]) {
          result.items[item.event] = {
            event: this.$t("analytics.events." + item.event),
            topic: null,
            count: 0,
            point: {},
            aggregate: {},
            items: [],
          };
        }

        let r = result.items[item.event];
        r.count += item.count;
        if (!r.point[item.date]) {
          r.point[item.date] = 0;
        }
        r.point[item.date] += item.count;
        if (!r.point[item.date]) {
          r.point[item.date] = null;
        }

        if (!result.point[item.date]) {
          result.point[item.date] = 0;
        }
        result.point[item.date] += item.count;
        if (!result.point[item.date]) {
          result.point[item.date] = null;
        }

        if (!r.aggregate["" + item.topic]) {
          r.aggregate["" + item.topic] = {
            event: r.event,
            topic: item.topic,
            count: 0,
            point: {},
            items: [],
          };
        }

        let s = r.aggregate["" + item.topic];
        s.count += item.count;
        s.point[item.date] = item.count + (s.point[item.date] || 0);
        if (!s.count) {
          s.count = null;
        }
        if (!s.point[item.date]) {
          s.point[item.date] = null;
        }

        s.items.push(item);
        r.items.push(item);
      }

      return result;
    },
    createHeaders(result, subheader) {
      const headers = [];
      if (!subheader)
        headers.push({ text: this.$t("analytics.event"), value: "event" });
      if (subheader)
        headers.push({ text: this.$t("analytics.topic"), value: "topic" });
      headers.push({ text: this.$t("analytics.count"), value: "count" });
      for (let point of Object.keys(result.point)) {
        let text = point;
        if (result.level === "DAY") text = this.$d(new Date(point), "day");
        else if (result.level === "WEEK")
          text = this.$d(new Date(point), "week");
        else if (result.level === "MONTH")
          text = this.$d(new Date(point), "month");
        headers.push({ text: text, value: "point." + point });
      }

      headers.push({ text: "", value: "chart", sortable: false });
      if (!subheader)
        headers.push({ text: "", value: "data-table-expand", sortable: false });
      return headers;
    },
    chartLoader() {
      if (window.google && window.google.charts) {
        return Promise.resolve(window.google);
      }

      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = () => resolve(window.google.charts);
        script.src = "https://www.gstatic.com/charts/loader.js";
        document.body.appendChild(script);
      }).then((loader) => {
        return new Promise((res) => {
          loader.load("current", { packages: ["corechart"] });
          loader.setOnLoadCallback(() => res(window.google));
        });
      });
    },
    addChart(next) {
      this.chartItems.push(next);
      this.drawChart();
    },
    removeChart(next) {
      let index = this.chartItems.indexOf(next);
      if (index !== -1) {
        this.chartItems.splice(index, 1);
      }
      this.drawChart();
    },
    clearChart() {
      this.chartItems.splice(0, this.chartItems.length);
      this.drawChart();
    },
    drawChart() {
      const items = this.chartItems;
      const chartData = (this.chartData = new google.visualization.DataTable());

      this.chartData.addColumn("date", "Date");

      for (let item of items) {
        this.chartData.addColumn(
          "number",
          item.event + (item.topic ? " [" + item.topic + "]" : "")
        );
      }

      for (let point of Object.keys(this.result.point)) {
        let row = [];
        row.push(new Date(point));
        for (let item of items) {
          row.push(item.point[point]);
        }
        chartData.addRow(row);
      }

      const options = {
        width: undefined,
        height: undefined,
        vAxis: {
          format: "decimal",
        },
        animation: {
          duration: 500,
          easing: "out",
          startup: true,
        },
      };

      if (!this.chartObject) return;
      this.chartObject.draw(chartData, options);
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  min-height: 512px;
}
</style>
