<template>
  <v-card>
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="8">
          {{ contact.company }}
          <br />
          {{ contact.degree }} {{ contact.givenName }} {{ contact.familyName }}
          <br />
          {{ contact.street }}
          <br v-if="contact.street2" />
          {{ contact.street2 }}
          <br />
          {{ contact.zip }} {{ contact.city }}
          <br v-if="contact.country" />
          {{ contact.country ? $t("countries." + contact.country) : "" }}
          <br v-if="contact.province" />
          {{ contact.province }}
        </v-col>
        <v-col cols="12" sm="4">
          {{ contact.email }}
          <br v-if="contact.website" />
          <a :href="contact.website" target="_blank" v-if="contact.website">
            {{ $t("contacts.website") }}
          </a>
          <br v-if="contact.phoneNumber" />
          {{ contact.phoneNumber }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    contact: { type: Object, required: true },
    title: { type: String, required: false },
  },
};
</script>

<style></style>
